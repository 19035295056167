<template>
  <div id="kohort-kia">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Dari </template>
                  <DatePicker 
                    style="width: 100%"
                    id="mulai_tanggal"
                    type="month"
                    format="MMMM YYYY"
                    placeholder="-- Pilih Bulan --"
                    v-model="data_search.tanggal_awal"
                  />
                </b-form-group>
                <!-- <pre>{{ data_search.tanggal_awal }}</pre> -->
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Sampai </template>
                  <DatePicker 
                    style="width: 100%"
                    id="selesai_tanggal"
                    type="month"
                    format="MMMM YYYY"
                    placeholder="-- Pilih Bulan --"
                    v-model="data_search.tanggal_akhir"
                  />
                </b-form-group>
                <!-- <pre>{{ data_search.tanggal_akhir }}</pre> -->
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <div style="width: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <h6 style="margin: 0; font-weight: bold">Puskesmas</h6>
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_puskesmas"
                        name="check_puskesmas"
                        v-model="checked"
                        @change="toggleSelect()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr />
                <b-row style="padding-left: 7.5px; margin-top: 15px">
                  <b-col
                    v-for="(item, idx) in options_puskesmas"
                    :key="idx"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                  >
                    <b-form-checkbox v-model="item.checked" @change="addSelect()">
                      {{ item.nama_puskesmas }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>

              <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <hr />
                <div style="width: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <h6 style="margin: 0; font-weight: bold">Poli / Ruangan</h6>
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_poli"
                        name="check_poli"
                        v-model="checked_poli"
                        @change="toggleSelectPoli()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr />
                <b-row style="padding-left: 7.5px; margin-top: 15px">
                  <b-col
                    v-for="(item, idx) in options_poli"
                    :key="idx"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <b-form-checkbox v-model="item.checked" @change="addSelectPoli()">
                      {{ item.nama_poli }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="display: flex; justify-content: flex-start">
                  <b-button @click="getDataKunjungan()" variant="primary" class="mr-2"
                    >Tampilkan</b-button
                  >
                  <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                  <b-button variant="warning" @click="resetDataKunjungan">Reset</b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
                <h5>
                  <strong>LAPORAN BULANAN - KOHORT KIA</strong>
                </h5>
                <p>{{ range_tanggal || "-" }}</p>
              </b-col>
            </b-row>

            
            <!-- <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h5 >
                  <strong>Tanggal: {{ data_search.bulan_awal }} - {{ data_search.bulan_akhir }}</strong>
                </h5>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <!-- <div class="text-end">Periode : {{ $moment(data_search.bulan_awal).format("LL") }} - {{ $moment(data_search.bulan_akhir).format("LL") }}</div> -->
                <b-table-simple bordered responsive hover>
                  <b-thead head-variant="dark" class="text-center">
                    <b-tr>
                      <b-th rowspan="3">No.</b-th>
                      <b-th rowspan="3">Register</b-th>
                      <b-th rowspan="2">Nama</b-th>
                      <b-th rowspan="3">Alamat</b-th>
                      <b-th colspan="6">Umur</b-th>
                      <b-th rowspan="2" colspan="3">Hamil Ke</b-th>
                      <b-th colspan="8">Laboratorium</b-th>
                      <b-th colspan="2">KEK</b-th>
                      <b-th colspan="15">Integrasi Program</b-th>
                      <b-th rowspan="2" colspan="2">Pendeteksi Faktor Resiko</b-th>
                      <b-th rowspan="2" colspan="3">Imunisasi</b-th>
                      <b-th rowspan="2" colspan="2">Buku KIA/Stiker P4K</b-th>
                      <b-th rowspan="2" colspan="2">Penolong Kehamilan</b-th>
                      <b-th colspan="4">Kelahiran</b-th>
                      <b-th rowspan="2" colspan="3">Ibu Nifas</b-th>
                      <b-th rowspan="3">Keterangan</b-th>
                    </b-tr>

                    <b-tr>
                      <b-th colspan="3">Ibu (Tahun)</b-th>
                      <b-th colspan="3">Kehamilan (Minggu)</b-th>
                      <b-th colspan="2">HB</b-th>
                      <b-th colspan="2">Protein</b-th>
                      <b-th colspan="2">Glukosa</b-th>
                      <b-th colspan="2">Gol. darah</b-th>
                      <b-th rowspan="2">Diperiksa LILA</b-th>
                      <b-th rowspan="2">KEK (LILA 23.5)</b-th>
                      <b-th colspan="3">HIV dari Ibu ke Anak (PPIA)</b-th>
                      <b-th colspan="3">TB dalam Kehamilan</b-th>
                      <b-th colspan="3">Kecacingan dalam Kehamilan</b-th>
                      <b-th colspan="3">Pencegahan IMS dalam Kehamilan</b-th>
                      <b-th colspan="3">Pencegahan Hepatitis dalam Kehamilan</b-th>

                      <b-th rowspan="2">Lahir Mati</b-th>
                      <b-th colspan="3">Lahir Lengkap</b-th>
                    </b-tr>

                    <b-tr>
                      <b-th>Ibu/Suami</b-th>
                      <b-th>{{ "<20" }}</b-th>
                      <b-th>{{ "20-35" }}</b-th>
                      <b-th>{{ ">35" }}</b-th>
                      <b-th>{{ "0-12" }}</b-th>
                      <b-th>{{ "13-24" }}</b-th>
                      <b-th>{{ ">24" }}</b-th>
                      <b-th>{{ "1 (Primi)" }}</b-th>
                      <b-th>{{ "2 - 4" }}</b-th>
                      <b-th>{{ ">4" }}</b-th>
                      <b-th>{{ "Anemia (8mg/dl)" }}</b-th>
                      <b-th>{{ "Anemia (<8mg/dl)" }}</b-th>
                      <b-th>Diperiksa</b-th>
                      <b-th>Hasil</b-th>
                      <b-th>Diperiksa</b-th>
                      <b-th>{{ ">140g/dl" }}</b-th>
                      <b-th>Diperiksa</b-th>
                      <b-th>Hasil</b-th>

                      <b-th>Pemeriksaan HIV</b-th>
                      <b-th>Hasil Test HIV (+)</b-th>
                      <b-th>Mendapat ART</b-th>
                      <b-th>Pemeriksaan Dahak</b-th>
                      <b-th>Hasil Test TBC (+)</b-th>
                      <b-th>Obat TB</b-th>

                      <b-th>Pemeriksaan Ankylostoma</b-th>
                      <b-th>Hasil Test (+)</b-th>
                      <b-th>Ibu Hamil Diobati</b-th>
                      <b-th>Pemeriksaan IMS </b-th>
                      <b-th>Hasil Test (+)</b-th>
                      <b-th>Ibu Hamil Diobati</b-th>
                      <b-th>Pemeriksaan Hepatitis</b-th>
                      <b-th>Hasil Test (+)</b-th>
                      <b-th>Ibu Hamil Diobati</b-th>

                      <b-th>Nakes</b-th>
                      <b-th>Non Nakes</b-th>
                      <b-th>TT 1</b-th>
                      <b-th>TT 24</b-th>
                      <b-th>TT Lengkap</b-th>
                      <b-th>Memiliki BUKU KIA</b-th>
                      <b-th>Memiliki Stiker P4K</b-th>
                      
                      <b-th>Nakes</b-th>
                      <b-th>Dukun</b-th>

                      <b-th>{{ "<2500" }}</b-th>
                      <b-th>{{ "2500-4000" }}</b-th>
                      <b-th>{{ ">4000" }}</b-th>
                      <b-th>6 Jam - 3 Hari</b-th>
                      <b-th>7 Hari - 14 Hari</b-th>
                      <b-th>{{ ">14 Hari - 40 Hari" }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="items.length">
                    <b-tr v-for="(item, index) in items" :key="item.kia_id">
                      <b-td class="text-center">{{ index + 1 }}.</b-td>
                      <b-td>{{ item.no_register || "-" }}</b-td>
                      <b-td>{{ item.nama_lengkap || "-" }}</b-td>
                      <b-td>{{ item.alamat_sekarang || "-" }}</b-td>

                      <b-td class="text-center">
                        <span v-if="item.usia_ibu_kurang_dari_20">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.usia_ibu_20_sampai_35">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.usia_ibu_lebih_dari_35">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.usia_kehamilan_0_sampai_12">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.usia_kehamilan_13_sampai_24">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.usia_kehamilan_lebih_dari_24">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hamil_ke_1">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hamil_ke_2_sd_4">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hamil_ke_lebih_dari_5">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hb_anemia_8mg">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hb_anemia_kurang_dari_8mg">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.protein_diperiksa">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.protein_hasil">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.glukosa_diperiksa">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.glukosa_hasil">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.gol_darah_diperiksa">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.gol_darah_hasil">
                          {{ item.gol_darah_hasil }}
                        </span>
                        <span v-else>-</span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.lila_diperiksa">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.kek_lila_23_koma_lima">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.dilakukan_tes && item.dilakukan_tes.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hasil_text_hiv && item.hasil_text_hiv.toLowerCase() != 'negatif'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.mendapatkan_art && item.mendapatkan_art.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diperiksa_dahak && item.diperiksa_dahak.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.tbc && item.tbc.toLowerCase() != 'negatif'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.obat && item.obat.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.ankylostoma && item.ankylostoma.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hasil_tes_kecacingan && item.hasil_tes_kecacingan.toLowerCase() != 'negatif'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diobati_kecacingan && item.diobati_kecacingan.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diperiksa_ims && item.diperiksa_ims.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hasil_tes_ims && item.hasil_tes_ims.toLowerCase() != 'negatif'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diterapi_ims && item.diterapi_ims.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diperiksa_hepatitis && item.diperiksa_hepatitis.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.hasil_tes_hepatitis && item.hasil_tes_hepatitis.toLowerCase() != 'negatif'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.diobati_hepatitis && item.diobati_hepatitis.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>

                      <b-td class="text-center">
                        <span v-if="item.pendeteksi_faktor_resiko_nakes">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.pendeteksi_faktor_resiko_non_nakes">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      
                      <b-td class="text-center">
                        <span v-if="item.imunisasi_TT_1">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.imunisasi_TT_2_4">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.imunisasi_TT_lengkap">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>

                      <b-td class="text-center">
                        <span v-if="item.buku_kia && item.buku_kia.toLowerCase() == 'memiliki'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.stiker_p4k && item.stiker_p4k.toLowerCase() == 'ya'">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      
                      <b-td class="text-center">
                        <span v-if="item.penolong_kehamilan_nakes">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.penolong_kehamilan_dukun">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      
                      <b-td class="text-center">
                        <span v-if="item.lahir_mati">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.lahir_lengkap_kurang_dari_2500">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.lahir_lengkap_2500_4000">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="item.lahir_lengkap_lebih_dari_4000">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      
                      <b-td class="text-center">
                        <span v-if="false">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="false">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      <b-td class="text-center">
                        <span v-if="false">
                          <b-icon icon="check-lg" variant="success"></b-icon>
                        </span>
                        <span v-else></span>
                      </b-td>
                      
                      <b-td class="text-center">
                        {{ item.keterangan }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  
                  <b-tbody v-else>
                    <b-tr>
                      <b-td colspan="55" class="text-center text-muted">
                        <i>Tidak Ada Data</i>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      options_kecamatan: [],
      options_kelurahan: [],
      options_diagnosa: [],
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        bulan_awal: null,
        bulan_akhir: null,
        tahun_awal: "",
        tahun_akhir: "",
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      },
      items: [],
      range_tanggal: "",
      sub_total_harga: 0,

      checked: false,
      checked_poli: "",
      select_puskesmas: [],
      select_poli: [],
      options_puskesmas: [],
      options_poli: [],

      optionBulan: [
        { text: "-- pilih bulan --", value: null },
        { text: "Januari", value: "Januari" },
        { text: "Februari", value: "Februari" },
        { text: "Maret", value: "Maret" },
        { text: "April", value: "April" },
        { text: "Mei", value: "Mei" },
        { text: "Juni", value: "Juni" },
        { text: "Juli", value: "Juli" },
        { text: "Agustus", value: "Agustus" },
        { text: "September", value: "September" },
        { text: "Oktober", value: "Oktober" },
        { text: "November", value: "November" },
        { text: "Desember", value: "Desember" },
      ],
      optionTahun: [
        { text: "-- pilih tuhan --", value: null },
        { text: "2021", value: "2021" },
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
      ],
      optionResiko: [
        { text: "pasien", value: "pasien" },
        { text: "keluarga", value: "keluarga" },
        { text: "masyarakat", value: "masyarakat" },
        { text: "dukun", value: "dukun" },
        { text: "kader", value: "kader" },
        { text: "bidan", value: "bidan" },
        { text: "perawat", value: "perawat" },
        { text: "dokter", value: "dokter" },
      ],
      optionKomplikasi: [
        { text: "dsog", value: "dsog" },
        { text: "hdk", value: "hdk" },
        { text: "abortus", value: "abortus" },
        { text: "pendarahan", value: "pendarahan" },
        { text: "infeksi", value: "infeksi" },
        { text: "kpd", value: "kpd" },
        { text: "lainnya", value: "lainnya" },
        { text: "dokter", value: "dokter" },
      ],
      optionRujukan: [
        { text: "puskesmas", value: "puskesmas" },
        { text: "klinik", value: "klinik" },
        { text: "rb", value: "rb" },
        { text: "rsia", value: "rsia" },
        { text: "rs", value: "rs" },
        { text: "lainnya", value: "lainnya" },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  activated() {
    this.data_search.tanggal_awal = new Date()
    this.data_search.tanggal_akhir = new Date()
    this.getData();
    this.getDataKunjungan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      let _puskesmas = { nama_puskesmas: vm.$store.state.puskesmas.nama_puskesmas }
      let puskesmas = await vm.$axios.post("/puskesmas/list", _puskesmas);
      if (puskesmas.data.data.length) {
        vm.options_puskesmas = puskesmas.data.data;
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
          let x = vm.options_puskesmas[i];
          x.checked = false;
        }
      }
      let _poli = { nama_poli: "kia" }
      let poli = await vm.$axios.post("/ms_poli/list", _poli);
      if (poli.data.data.length) {
        vm.options_poli = poli.data.data;
        for (let i = 0; i < vm.options_poli.length; i++) {
          let x = vm.options_poli[i];
          x.checked = false;
        }
      }
    },
    getDataKunjungan() {
      let vm = this;
      if (vm.select_puskesmas.length) {
        vm.data_search.bulk_puskesmas_id = vm.select_puskesmas;
      }
      if (vm.select_poli.length) {
        vm.data_search.bulk_ms_poli_id = vm.select_poli;
      }

      vm.data_search.bulan_awal = vm.$moment(vm.data_search.tanggal_awal).format("MM")
      vm.data_search.tahun_awal = vm.$moment(vm.data_search.tanggal_awal).format("YYYY")
      vm.data_search.bulan_akhir = vm.$moment(vm.data_search.tanggal_akhir).format("MM")
      vm.data_search.tahun_akhir = vm.$moment(vm.data_search.tanggal_akhir).format("YYYY")
      console.log("data search", vm.data_search);
      vm.range_tanggal = `${vm.$moment(vm.data_search.tanggal_awal).format("MMMM YYYY")} - ${vm.$moment(vm.data_search.tanggal_akhir).format("MMMM YYYY")}`
      vm.$axios
        .post("/kia/laporan_kohort_kia", vm.data_search)
        .then((res) => {
          // console.log(res, "ini respon");
          if (res.data.status == 200) {
            let x = res.data.data
            vm.items = x
            console.log(vm.items);
          } else {
            vm.items = [];
            vm.$store.commit("set_alert", {
              msg: "TIDAK ADA DATA",
              variant: "danger",
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$store.commit("set_alert", {
            msg: err.message,
            variant: "danger",
            showing: true,
          });
        });
    },
    toggleSelect: function () {
      let select = this.checked;
      this.options_puskesmas.forEach(function (data) {
        data.checked = select;
      });
      this.select_puskesmas = [];
      for (let i = 0; i < this.options_puskesmas.length; i++) {
        let x = this.options_puskesmas[i];
        if (x.checked == true) {
          this.select_puskesmas.push(x.id);
        }
      }
      this.$forceUpdate();
    },
    toggleSelectPoli: function () {
      let select = this.checked_poli;
      // console.log(this.checked_poli);
      this.options_poli.forEach(function (data) {
        data.checked = select;
      });
      this.select_poli = [];
      for (let i = 0; i < this.options_poli.length; i++) {
        let x = this.options_poli[i];
        if (x.checked == true) {
          this.select_poli.push(x.ms_poli_id);
        }
      }
      this.$forceUpdate();
    },
    addSelect() {
      let vm = this;
      vm.select_puskesmas = [];
      for (let i = 0; i < vm.options_puskesmas.length; i++) {
        let x = vm.options_puskesmas[i];
        if (x.checked == true) {
          vm.select_puskesmas.push(x.id);
        }
      }
    },
    addSelectPoli() {
      let vm = this;
      vm.select_poli = [];
      for (let i = 0; i < vm.options_poli.length; i++) {
        let x = vm.options_poli[i];
        if (x.checked == true) {
          vm.select_poli.push(x.ms_poli_id);
        }
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
    resetDataKunjungan() {
      this.data_search = {
        tanggal_awal: new Date(),
        tanggal_akhir: new Date(),
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      }
      this.select_puskesmas = []
      this.select_poli = []
      this.getData()
      this.getDataKunjungan()
    },
    setTanggal() {
      this.data_search.tanggal_awal = new Date()
      this.data_search.tanggal_akhir = new Date()
    }
  },
};
</script>

<style>
#kohort-kia .custom-table td {
  padding: 0 !important;
  font-size: 14px;
  font-weight: bold;
  border: hidden !important;
}

#kohort-kia .custom-control-label {
  padding-top: 2.5px;
}
.top-text-variant {
  font-size: 10px;
}
</style>
